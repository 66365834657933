import { Environment } from './environment.interface';

export const environment: Environment = {
  production: false,
  maintenance: false,
  namespace: 'int',
  pwaEnabled: true,
  name: 'Recruitment Portal',
  title: 'Recruitment Portal',
  apiEndpoint: 'https://uat-tns-api.joinx.co.uk',
  mapApiKey: 'AIzaSyAgDUII_kvGfCJNmu4qhhzjl8YNzblV9Ng',
  globalDashboardUrl: 'https://dashboard-integration.tnssng.com',
  showCaptchaAppForm: true,
  gtmID: {
    tns: 'GTM-KHH8MNJ',
  },
  captchaApiKey: {
    tns: '6LcGkdUZAAAAAHksK5a9-tlte_s7T2Lyaec5sPTB',
    sng: '6LckkdUZAAAAAHRXHlEhR1XHx5hp4kfaO6MEfyD1',
    sngroi: '6LckkdUZAAAAAHRXHlEhR1XHx5hp4kfaO6MEfyD1',
    sngni: '6LckkdUZAAAAAHRXHlEhR1XHx5hp4kfaO6MEfyD1',
  },
  links: {
    tnsUserPolicy: 'https://www.thornbury-nursing.com/privacy-policy/',
    sngUserPolicy: 'https://www.scottish-nursing-guild.com/privacy-policy/',
    sngroiUserPolicy: 'https://www.scottish-nursing-guild.com/privacy-policy/',
    tnsUserPrivacy: 'https://www.thornbury-nursing.com/acceptable-use-policy/',
    sngUserPrivacy:
      'https://www.scottish-nursing-guild.com/acceptable-use-policy/',
    sngroiUserPrivacy:
      'https://www.scottish-nursing-guild.com/acceptable-use-policy/',
    tnsGroupCompanies: 'https://thornbury-nursing.com/affiliates/',
    sngGroupCompanies: 'https://scottish-nursing-guild.com/affiliates/',
    sngroiGroupCompanies: 'https://scottish-nursing-guild.com/affiliates/',
    tnsRegister: 'https://thornbury-nursing.com/join-thornbury/register/',
    sngRegister: 'https://scottish-nursing-guild.com/join-the-guild/register/',
    sngroiRegister:
      'https://scottish-nursing-guild.com/join-the-guild/register/',
    xeniooLandingUrl:
      'https://landing.xenioo.com/lp/app02/05402a3e-ba6f-474c-88f4-50a101cae7ec',
  },
  scope: {
    sngDomain: 'sng-uat',
  },
  seo: {
    robots: 'noindex, nofollow',
    tnsTitle: 'Thornbury Nursing Services',
    sngTitle: 'Welcome to the Scottish Nursing Guild',
    sngroiTitle: 'Welcome to the Scottish Nursing Guild',
    tnsDescription:
      'Thornbury Nursing Services is a nursing agency that responds to last minute shift requests.',
    sngDescription:
      'The Scottish Nursing Guild is a nursing agency that responds to last minute shift requests',
    sngroiDescription:
      'The Scottish Nursing Guild is a nursing agency that responds to last minute shift requests',
  },
  divisionMask: {
    tns: {
      telephone: '0345 120 5252',
      telephone_rtc: '0345 120 5251',
      email: 'recruitment@tnsltd.com',
      label: 'TNS+Temporary',
      scope: 'tns',
      mainAddress:
        'Unit A, Estune Business Park, Wild Country Lane, Long Ashton, Bristol, BS41 9FH',
      compliancePortalUrl: 'https://uat.compliance-thornbury.com/',
      privacyPolicyLink: 'https://thornbury-nursing.com/privacy-policy/',
    },
    sng: {
      telephone: '0131 229 9388',
      telephone_rtc: '0345 120 5253',
      email: 'recruitment@nursingguild.com',
      label: 'SNG+Temporary',
      scope: 'sng',
      mainAddress: '160 Dundee Street, Edinburgh, Scotland, EH11 1DQ',
      compliancePortalUrl: 'https://uat.compliance-theguild.com/',
      privacyPolicyLink: 'https://nursingguild.com/privacy-policy/',
    },
    sngni: {
      telephone: '0333 323 0338',
      email: 'recruitment@nursingguild.com',
      label: 'SNG+Temporary+Northern Ireland',
      scope: 'sng',
      mainAddress: '160 Dundee Street, Edinburgh, Scotland, EH11 1DQ',
      compliancePortalUrl: 'https://uat.compliance-theguild.com/',
      privacyPolicyLink: 'https://nursingguild.com/privacy-policy/',
    },
    sngroi: {
      telephone: '+353 190 23081',
      email: 'dublinrecruitment@nursingguild.com',
      label: 'SNG+Temporary+Dublin',
      scope: 'sngroi',
      mainAddress: 'Suite 201 & 202, 34 Fitzwilliam Square South, Dublin 2',
      compliancePortalUrl: 'https://uat.compliance-theguild.com/',
      privacyPolicyLink: 'https://nursingguild.com/privacy-policy/',
    },
  },
  pathsConfig: [
    {
      id: 'application-form',
      muteOutright: true,
      sectionsRequiredMute: [],
      divisionMaskMute: [],
      hasValues: '',
    },
    {
      id: 'contact-us',
      muteOutright: true,
      sectionsRequiredMute: [],
      divisionMaskMute: [],
      hasValues: '',
    },
    {
      id: 'admin',
      muteOutright: true,
      sectionsRequiredMute: [],
      divisionMaskMute: [],
      hasValues: '',
    },
    {
      id: 'book-a-help-call',
      muteOutright: true,
      sectionsRequiredMute: [],
      divisionMaskMute: [],
      hasValues: '',
    },
    {
      id: 'admin-only-section',
      muteOutright: true,
      sectionsRequiredMute: [],
      divisionMaskMute: [],
      hasValues: '',
    },
    {
      id: 'screening-call',
      muteOutright: false,
      sectionsRequiredMute: [],
      divisionMaskMute: [],
      hasValues: '',
    },
    {
      id: 'interview',
      muteOutright: false,
      sectionsRequiredMute: ['screening-call'],
      divisionMaskMute: [],
      hasValues: '',
    },
    {
      id: 'references-%26-self-assessment',
      muteOutright: false,
      sectionsRequiredMute: ['screening-call'],
      divisionMaskMute: [],
      hasValues: '',
    },
    {
      id: 'workbook',
      muteOutright: false,
      sectionsRequiredMute: ['screening-call'],
      divisionMaskMute: ['sngroi'],
      hasValues: '',
    },
    {
      id: 'disclosure',
      muteOutright: false,
      sectionsRequiredMute: ['screening-call'],
      divisionMaskMute: [],
      hasValues: '',
    },
    {
      id: 'work-%26-education-history',
      muteOutright: false,
      sectionsRequiredMute: ['screening-call'],
      divisionMaskMute: [],
      hasValues: '',
    },
    {
      id: 'occupational-health',
      muteOutright: false,
      sectionsRequiredMute: ['screening-call'],
      divisionMaskMute: [],
      hasValues: '',
    },
    {
      id: 'declarations',
      muteOutright: false,
      sectionsRequiredMute: ['screening-call'],
      divisionMaskMute: [],
      hasValues: 'declaration_forms',
    },
    {
      id: 'id-verification',
      muteOutright: false,
      sectionsRequiredMute: ['screening-call'],
      divisionMaskMute: [],
      hasValues: '',
    },
    {
      id: 'competency-record',
      muteOutright: false,
      sectionsRequiredMute: ['screening-call'],
      divisionMaskMute: [],
      hasValues: '',
    },
    {
      id: 'pre-work-requirements',
      muteOutright: true,
      sectionsRequiredMute: [],
      divisionMaskMute: [],
      hasValues: '',
    },
    {
      id: 'agency-agreement',
      muteOutright: true,
      sectionsRequiredMute: [],
      divisionMaskMute: [],
      hasValues: '',
    },
    {
      id: 'welcome-pack',
      muteOutright: true,
      sectionsRequiredMute: ['pre-work-requirements', 'agency-agreement'],
      divisionMaskMute: [],
      hasValues: '',
    },
    {
      id: 'welcome-call',
      muteOutright: true,
      sectionsRequiredMute: [],
      divisionMaskMute: [],
      hasValues: '',
    },
  ],
  IDVerificationDownloads: {
    tns: 'http://library.thornbury-nursing.com/docverificationform.pdf',
    sng: 'http://library.scottish-nursing-guild.com/docverificationform.pdf',
    sngni: 'http://library.scottish-nursing-guild.com/docverificationform.pdf',
    sngroi:
      'http://library.scottish-nursing-guild.com/docverificationformroi.pdf',
    nvbForm:
      'https://library.scottish-nursing-guild.com/NVB_1_Vetting_invitation_ERF.pdf',
  },
  competencyRecordDownloads: {
    tns: 'http://library.thornbury-nursing.com/tns_candidate_compliance_Info.pdf',
    sng: 'http://library.scottish-nursing-guild.com/sng_candidate_compliance_Info.pdf',
    sngni:
      'http://library.scottish-nursing-guild.com/sng_candidate_compliance_Info.pdf',
    sngroi:
      'http://library.scottish-nursing-guild.com/sng-roi_candidate_compliance_Info.pdf',
  },
  occupationalHealthDownloads: {
    tns: [
      {
        name: 'Occupational Health Guide - TNS',
        link: 'http://library.thornbury-nursing.com/vettingocchinfo.pdf',
      },
      {
        name: 'Occupational Health Guide - Official guidance from Healthier Business',
        link: 'http://library.thornbury-nursing.com/occupationalhealthrequirements.pdf',
      },
      {
        name: 'General provider form (Non-EPP only)',
        link: 'http://library.thornbury-nursing.com/hbproviderforms.pdf',
      },
      {
        name: 'BCG Scar provider form',
        link: 'http://library.thornbury-nursing.com/hb_bcg_declaration.pdf',
      },
      {
        name: 'IVS provider form (EPP only)',
        link: 'http://library.thornbury-nursing.com/hbivsconfirmation.pdf',
      },
    ],
    sng: [
      {
        name: 'Occupational Health Guide - SNG',
        link: 'http://library.scottish-nursing-guild.com/vettingocchinfo.pdf',
      },
      {
        name: 'Occupational Health Guide - Official guidance from Healthier Business',
        link: 'http://library.scottish-nursing-guild.com/hb_main_guidance_scot_ni.pdf',
      },
      {
        name: 'General provider form (Non-EPP only)',
        link: 'http://library.scottish-nursing-guild.com/hb_provider_form_non-epp_scot_ni.pdf',
      },
      {
        name: 'BCG Scar provider form',
        link: 'http://library.scottish-nursing-guild.com/hb_bcg_scar_verification.pdf',
      },
      {
        name: 'IVS provider form (EPP only)',
        link: 'http://library.scottish-nursing-guild.com/hb_ivs_confirmation_scot_ni.pdf',
      },
    ],
    sngni: [
      {
        name: 'Occupational Health Guide - SNG',
        link: 'http://library.scottish-nursing-guild.com/vettingocchinfo.pdf',
      },
      {
        name: 'Occupational Health Guide - Official guidance from Healthier Business',
        link: 'http://library.scottish-nursing-guild.com/hb_main_guidance_scot_ni.pdf',
      },
      {
        name: 'General provider form (Non-EPP only)',
        link: 'http://library.scottish-nursing-guild.com/hb_provider_form_non-epp_scot_ni.pdf',
      },
      {
        name: 'BCG Scar provider form',
        link: 'http://library.scottish-nursing-guild.com/hb_bcg_scar_verification.pdf',
      },
      {
        name: 'IVS provider form (EPP only)',
        link: 'http://library.scottish-nursing-guild.com/hb_ivs_confirmation_scot_ni.pdf',
      },
    ],
    sngroi: [
      {
        name: 'Occupational Health Guide - SNG',
        link: 'http://library.scottish-nursing-guild.com/vettingocchinforoi.pdf',
      },
      {
        name: 'Occupational Health Guide - Official guidance from Healthier Business',
        link: 'http://library.scottish-nursing-guild.com/hbmainguidanceroi.pdf',
      },
      {
        name: 'General provider form (Non-EPP only)',
        link: 'http://library.scottish-nursing-guild.com/hb_provider_form_non-epp_roi.pdf',
      },
      {
        name: 'BCG Scar provider form',
        link: 'http://library.scottish-nursing-guild.com/hb_bcg_scar_verificationroi.pdf',
      },
      {
        name: 'IVS provider form (EPP only)',
        link: 'http://library.scottish-nursing-guild.com/hbivsconfirmationroi.pdf',
      },
    ],
  },
  featureFlags: {
    roiIdVerification: true,
  },
};
